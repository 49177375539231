<template>
  <div v-if="user">
    <section class="section is-main-section" v-if="model">
      <h1 class="title">
        {{ title }} - {{ model.code }}
        <div class="buttons is-pulled-right">
          <b-button
            v-if="
              model.status != 'received' &&
              model.branch_branchToshipment_script_to_branch_id.id ==
                user.branch_id &&
              checkedRows.length > 0
            "
            class="is-success is-pulled-right"
            @click="receive()"
            icon-left="check"
          >
            ຮັບເຄື່ອງ
          </b-button>
          <b-button class="is-info" @click="gotoprint" icon-left="printer">
            ພິມ
          </b-button>
        </div>
      </h1>
      <div class="columns" v-if="model">
        <div class="column">
          <div>
            ສາຂາຕົ້ນທາງ:
            <b> {{ model.branch.code }} {{ model.branch.name }} </b>
          </div>
          <div>
            ປ້າຍລົດ: <b>{{ model ? model.vehicle.license_no : "" }}</b>
          </div>
        </div>
        <div class="column is-7">
          ສາຂາປາຍທາງ:
          <b style="font-size: 3em">
            {{ model.branch_branchToshipment_script_to_branch_id.name }}
            {{ model.branch_branchToshipment_script_to_branch_id.code }}
          </b>
        </div>
      </div>
      <hr />
      <b-table
        v-if="model && model.shipment_script_detail"
        :loading="isLoading"
        :paginated="perPage < model.shipment_script_detail.length"
        :per-page="perPage"
        :striped="true"
        :hoverable="true"
        default-sort="sq_number"
        :data="model.shipment_script_detail"
        :current-page="currentPage"
        :pagination-rounded="true"
        :checked-rows.sync="checkedRows"
        checkable
        :checkbox-position="'left'"
        :is-row-checkable="(row) => row.status == 'waiting'"
      >
        <b-table-column label="ລຳດັບ" v-slot="props">
          {{ props.row.shipment.sq_number }}
        </b-table-column>
        <b-table-column label="#" v-slot="props">
          {{ props.index * currentPage + 1 }}
        </b-table-column>
        <b-table-column label="ລະຫັດ" field="code" sortable v-slot="props">
          <div>{{ props.row.shipment.code }}</div>
          <ul
            v-if="
              props.row.shipment.parcel && props.row.shipment.parcel.length > 0
            "
          >
            <li v-for="(p, i) in props.row.parcel" :key="i">
              - {{ p.shipment.parceltype.name }} x {{ p.qty }}
            </li>
          </ul>
        </b-table-column>
        <b-table-column
          label="ຜູ້ສົ່ງ"
          field="sendername"
          sortable
          v-slot="props"
        >
          {{ props.row.shipment.sendername }} {{ props.row.shipment.sendertel }}
        </b-table-column>
        <b-table-column
          label="ຜູ້ຮັບ"
          field="receivername"
          sortable
          v-slot="props"
        >
          {{ props.row.shipment.receivername }}
          {{ props.row.shipment.receivertel }}
        </b-table-column>
        <b-table-column label="ຄ່າຝາກ" field="amount" sortable v-slot="props">
          {{ props.row.shipment.amount | formatnumber }}
        </b-table-column>
        <b-table-column label="ການຊໍາລະ" field="status" sortable v-slot="props">
          <div class="tags" v-if="!props.row.shipment.is_receiver_pay">
            <span class="tag is-success"> ຈ່າຍແລ້ວ </span>
            <span class="tag is-info">
              {{ props.row.shipment.fee_payment_mode }}
            </span>
          </div>
          <span
            v-if="
              props.row.shipment.is_receiver_pay &&
              !props.row.shipment.is_fee_in_cod
            "
            class="tag is-warning"
          >
            ເກັບຄ່າສົ່ງປາຍທາງ
          </span>
          <span
            v-if="
              props.row.shipment.is_receiver_pay &&
              props.row.shipment.is_fee_in_cod
            "
            class="tag is-danger"
          >
            ເກັບຄ່າສົ່ງປາຍທາງ (ຫັກຈາກ COD)
          </span>
        </b-table-column>

        <empty-table
          v-if="model.shipment_script_detail.length == 0"
          :isLoading="isLoading"
        />
      </b-table>
      <hr />
      <div class="columns" v-if="checkedRows">
        <div class="column">
          <div class="has-text-centered">ລວມ</div>
          <div class="has-text-centered" style="font-size: 2em">
            {{ total | formatnumber }}
          </div>
        </div>
        <div class="column">
          <div class="has-text-centered">ຈ່າຍແລ້ວ</div>
          <div class="has-text-centered" style="font-size: 2em">
            {{ paid | formatnumber }}
          </div>
          <div class="columns">
            <div class="column">
              <div class="has-text-centered">ເງິນສົດ (cash)</div>
              <div class="has-text-centered" style="font-size: 2em">
                {{ sumCash | formatnumber }}
              </div>
            </div>
            <div class="column">
              <div class="has-text-centered">ONEPAY</div>
              <div class="has-text-centered" style="font-size: 2em">
                {{ sumOnePay | formatnumber }}
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="has-text-centered">ເກັບຄ່າສົ່ງປາຍທາງ</div>
          <div class="has-text-centered" style="font-size: 2em">
            {{
              _.sumBy(checkedRows, (s) => {
                return s.shipment.is_receiver_pay ? s.shipment.amount : 0;
              }) | formatnumber
            }}
          </div>
        </div>
      </div>
    </section>

    <b-loading
        :is-full-page="true"
        v-model="loadingFlag"
        :can-cancel="false"
    ></b-loading>

  </div>
</template>

<script>
import Axios from "axios";
import { get } from "vuex-pathify";
import swal from "sweetalert2";
import axios from "axios";
import EmptyTable from "../components/EmptyTable.vue";
export default {
  name: "ShipmentScriptView",
  components: { EmptyTable },
  computed: {
    user: get("user"),
    total() {
      return this._.sumBy(this.checkedRows, (s) => {
        return s.shipment.amount;
      });
    },
    paid() {
      return this._.sumBy(this.checkedRows, (s) => {
        return s.shipment.is_receiver_pay ? 0 : s.shipment.amount;
      });
    },
    sumCash() {
      return this._.sumBy(this.checkedRows, (s) => {
        return !s.shipment.is_receiver_pay &&
          s.shipment.fee_payment_mode.toLowerCase() == "cash"
          ? s.shipment.amount
          : 0;
      });
    },
    sumOnePay() {
      return this._.sumBy(this.checkedRows, (s) => {
        return !s.shipment.is_receiver_pay &&
          s.shipment.fee_payment_mode.toLowerCase() == "onepay"
          ? s.shipment.amount
          : 0;
      });
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      isLoading: false,
      checkboxPosition: "left",
      checkedRows: [],
      code: null,
      title: "ໃບນໍາສົ່ງ",
      model: null,
      loadingFlag: false
    };
  },
  mounted() {
    this.code = this.$route.params["code"];
    this.get();
  },
  methods: {
    get() {
      Axios.get("shipmentscript/get/" + this.code).then((r) => {
        this.model = r;
        if (this.model.shipment_script_detail) {
          this.checkedRows = this.model.shipment_script_detail;
        }
      });
    },
    gotoprint() {
      if (this.isAndroid) {
        console.log("App print not support");
      } else {
        this.$router.push({ path: `/shipmentscriptprint/${this.code}` });
      }
    },
    receive() {
      if (!this.checkedRows || this.checkedRows.length == 0) {
        return;
      }

      const waitings = this._.filter(
        this.checkedRows,
        (c) => c.status == "waiting"
      );
      console.log("waitings", waitings);
      const items = this._.map(waitings, (c) => c.shipment.id);

      this.loadingFlag = true;
      axios
        .post(`parcels/doloadfrombus`, {
          shipmentscriptno: this.code,
          items: items,
        })
        .then((r) => {
          this.loadingFlag = false;
          console.log(r);
          swal.fire("ສຳເລັດ", "", "success");
          window.history.go(-1);
        }).catch(()=> {
        this.loadingFlag = false;
      });
    },
  },
};
</script>
